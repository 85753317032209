<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
    <md-card >
      <md-card-header>
        <md-card-header-text>
          <div v-if="instance.id" class="md-title">Nueva configuración cuenta contable</div>
            <div v-else class="md-title">Editando configuración cuenta contable</div>
        </md-card-header-text>
        <md-menu md-size="auto">
          <md-button class="md-icon-button">
          <router-link :to='`/create_setting`' @click.native="$router.go()"
          class="sub-link"><md-icon>add</md-icon></router-link>
          </md-button>
        </md-menu>
        <md-menu md-size="big" md-direction="bottom-end">
          <md-button class="md-icon-button"
            @click="go_to(`/stores/${company.id}`)">
            <md-icon>keyboard_backspace</md-icon>
          </md-button>
        </md-menu>
      </md-card-header>
      <md-card-content>
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-small-size-100 md-xsmall-size-100">
          <div class="md-layout-item">
            <md-field :class="getValidationClass('description')" :title="instance.description">
              <label for="description">Descripción</label>
            <md-select v-model="instance.description"
              :disabled="sending"
            >
            <md-option :value="item.value" :title="item.text"
              v-for="(item, key) in choosen_type_account"
              :key="key">{{item.text}}</md-option>
            </md-select>
            <span class="md-error"
              v-if="!$v.instance.description.required">
              La descripcón es requerida</span>
            </md-field>
          </div>
        </div>
        <div class="md-layout-item md-small-size-100">
          <md-autocomplete autocomplete="off"
            :class="getValidationClass('account')"
            id="account_name" class="autocomplete"
            :title="!instance.account_bank_name ? instance.account_name :
            instance.account_name + ' ('+ instance.account_bank_name+' )'"
            :disabled="sending"
            v-model="instance.account_name"
            md-input-name="account"
            :md-options="list_accounts"
            @md-selected="set_account"
            @md-changed="get_list_accounts" >
          <label for="account_name">Cuenta contable</label>
          <template
            slot="md-autocomplete-item" slot-scope="{ item, term }">
            <span v-if="item.account_bank" :title="item.name +' ('+ item.name_account_bank+' )'">
              {{ item.name }} - {{ item.nature }}  ( {{item.name_account_bank}} )</span>
            <md-highlight-text :md-term="term" v-if="!item.account_bank">
              {{ item.name }} - {{ item.nature }}</md-highlight-text>
          </template>
          <span class="md-error"
            v-if="!$v.instance.account.required">
            La cuenta contable es requerida</span>
          </md-autocomplete>
        </div>
        <div class="md-layout-item">
          <br>
          <md-button class="md-primary"
            v-if="!sending"
            @click="validForm()">
            <span v-if="!instance.id">Guardar</span>
            <span v-else>Guardar Cambios</span>
          </md-button>
          <md-progress-spinner v-if="sending" class="md-primary"
          :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
        </div>
      </div>
      </md-card-content>
    </md-card>
    <br>
    <br>
    </div>
    <md-snackbar
      :md-active.sync="confSaved">
    La configuracion para {{ instance.description }} ha sido actualizada!</md-snackbar>
    <md-progress-bar md-mode="indeterminate" v-if="sending" />
  </div>
</template>
<script>
/* eslint-disable */
import Swal from 'sweetalert2';
import { validationMixin } from 'vuelidate'
import {
  required,
  email,
} from 'vuelidate/lib/validators'

export default {
  name: 'FormConfiguracion',
  created(){
    this.company = this.$store.state.company_obj
    this.instance.id = this.$route.params.id
    if (this.instance.id){
      this.retrieveRequest();
    }
  },
  mixins: [validationMixin],
  data() {
    return {
      company: null,
      base: process.env.VUE_APP_BASE_URL,
      list_accounts: [],
      book_diary: null,
      instance: {
        account: null,
        description: null,
      },
      url_diarybook: 'diary_book_detail/',
      url_accounts: 'accounting_account/',
      url: 'account_config/',
      confSaved: false,
      sending: false,
      choosen_type_account: [
        {value:'IMPUESTO DISTRIBUCION DE PETROLEO', text: 'IMPUESTO DISTRIBUCION DE PETROLEO'},
        {value:'COMBUSTIBLES', text: 'COMBUSTIBLES'},
        {value:'COMPRAS', text: 'COMPRAS'},
        {value:'VENTAS', text: 'VENTAS'},
        {value:'SERVICIOS PRESTADOS', text: 'SERVICIOS PRESTADOS'},
        {value:'BANCOS', text: 'BANCOS'},
        {value:'IVA DEBITO', text: 'IVA DEBITO'},
        {value:'IVA CREDITO', text: 'IVA CREDITO'},
        {value:'CLIENTES', text: 'CLIENTES'},
        {value:'PROVEEDORES', text: 'PROVEEDORES'},
        {value:'IMPORTACIONES', text: 'IMPORTACIONES'},
        {value:'ATENCION A CLIENTES Y EMPLEADOS', text: 'ATENCION A CLIENTES Y EMPLEADOS'},
        {value:'LUBRICANTES Y REPUESTOS DE VEHICULOS', text: 'LUBRICANTES Y REPUESTOS DE VEHICULOS'},
        {value:'PAPELERÍA Y UTILES DE OFICINA', text: 'PAPELERÍA Y UTILES DE OFICINA'},
        {value:'AGUA PURA', text: 'AGUA PURA'},
        {value:'ALIMENTOS Y BEBIDAS', text: 'ALIMENTOS Y BEBIDAS'},
        {value:'LUBRICANTES Y REPUESTOS DE MAQUINARIA', text: 'LUBRICANTES Y REPUESTOS DE MAQUINARIA'},
        {value:'MEDICAMENTOS', text: 'MEDICAMENTOS'},
        {value:'MATERIALES DE LIMPIEZA', text: 'MATERIALES DE LIMPIEZA'},
        {value:'OTRAS COMPRAS', text: 'OTRAS COMPRAS'},
        {value:'SUSCRIPCIONES Y SEGUROS', text: 'SUSCRIPCIONES Y SEGUROS'},
        {value:'TELÉFONO, INTERNET Y AGUA', text: 'TELÉFONO, INTERNET Y AGUA'},
        {value:'ENERGÍA ELÉCTRICA', text: 'ENERGÍA ELÉCTRICA'},
        {value:'REPARACIÓN Y PARQUEO DE VEHÍCULOS', text: 'REPARACIÓN Y PARQUEO DE VEHÍCULOS'},
        {value:'ARRENDAMIENTOS', text: 'ARRENDAMIENTOS'},
        {value:'ARRENDAMIENTOS Y SERVICIOS DE ASESORÍA', text: 'ARRENDAMIENTOS Y SERVICIOS DE ASESORÍA'},
        {value:'MANTENIMIENTO DE MOBILIARIO Y EQUIPO', text: 'MANTENIMIENTO DE MOBILIARIO Y EQUIPO'},
        {value:'OTROS SERVICIOS', text: 'OTROS SERVICIOS'},
        {value:'SERVICIOS DE SEGURIDAD', text: 'SERVICIOS DE SEGURIDAD'},
        {value:'SERVICIOS DE TRANSPORTE Y ENCOMIENDA', text: 'SERVICIOS DE TRANSPORTE Y ENCOMIENDA'},
        {value:'ASESORÍAS', text: 'ASESORÍAS'},
        {value:'SERVICIOS CONTABLES', text: 'SERVICIOS CONTABLES'},
        {value:'MAQUINARIA Y HERRAMIENTA', text: 'MAQUINARIA Y HERRAMIENTA'},
        {value:'MOBILIARIO Y EQUIPO', text: 'MOBILIARIO Y EQUIPO'},
        {value:'VEHICULOS', text: 'VEHICULOS'},
        {value:'INMUEBLES', text: 'INMUEBLES'},
        {value:'EQUIPO DE COMPUTO', text: 'EQUIPO DE COMPUTO'},
        {value:'GASTOS DE INSTALACION', text: 'GASTOS DE INSTALACION'},
        {value:'OTROS ACTIVOS', text: 'OTROS ACTIVOS'},
        {value:'SUELDOS, SALARIOS Y BONIFICACIONES', text: 'SUELDOS, SALARIOS Y BONIFICACIONES'},
        {value:'CUOTA PATRONAL IGSS, IRTRA E INTECAP', text: 'CUOTA PATRONAL IGSS, IRTRA E INTECAP'},
        {value:'AGUINALDO Y BONO 14', text: 'AGUINALDO Y BONO 14'},
        {value:'VACACIONES PAGADAS', text: 'VACACIONES PAGADAS'},
        {value:'OTROS GASTOS NO AFECTOS', text: 'OTROS GASTOS NO AFECTOS'},
        {value:'COMPRAS EXCENTAS', text: 'COMPRAS EXCENTAS'},
        {value:'SERVICIOS PROFESIONALES', text: 'SERVICIOS PROFESIONALES'},
        {value:'ABARROTES', text: 'ABARROTES'},

        {value:'VESTUARIO Y ACCESORIOS', text: 'VESTUARIO Y ACCESORIOS'},
        {value:'FERRETERÍA', text: 'FERRETERÍA'},
        {value:'MEDICAMENTOS Y GASTOS MÉDICOS', text: 'MEDICAMENTOS Y GASTOS MÉDICOS'},
        {value:'ABARROTES, UTENCILIOS DE LIMPIEZA', text: 'ABARROTES, UTENCILIOS DE LIMPIEZA'},
        {value:'MANTENIMIENTO DE ACTIVOS MOBILES E INMOBILIARIOS', text: 'MANTENIMIENTO DE ACTIVOS MOBILES E INMOBILIARIOS'},


        {value:'TRANSPORTE Y ENCOMIENDAS', text: 'TRANSPORTE Y ENCOMMIENDAS'},
        {value:'SERVICIOS MÉDICO', text: 'SERVICIOS MÉDICO'},
        {value:'SERVICIOS TÉCNICOS', text: 'SERVICIOS TÉCNICOS'},
        {value:'PUBLICIDAD Y PROPAGANDA', text: 'PUBLICIDAD Y PROPAGANDA'},

      ],
    };

  },
  validations: {
    instance: {
      description: {
        required,
      },
      account: {
        required,
      },
    },
  },
  methods: {
    go_to(url) {
      this.$router.push(url);
    },
    set_account(obj) {
      this.instance.account_name = obj.name;
      if (obj.name_account_bank) {
        this.instance.account_name = obj.name +' ('+ obj.name_account_bank+' )';
      }
      this.instance.nomenclature = obj.nomenclature;
      this.instance.account = obj.id;
    },
    get_list_accounts(searchTerm) {
      const payload = {};
      if (searchTerm) {
        payload.toke = searchTerm
        payload.filter = "True"
        payload.accountSearch = "1"
      }
      filter: "True"
      const self = this;
      this.sendRequest(payload, this.url_accounts).then((data) => {
        self.list_accounts = data.data;
      }).catch((error) => {
        console.log(error);
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    save_storage(key, data) {
      localStorage.setItem([key], JSON.stringify(data));
    },
    async get_data_storage(name) {
      return await JSON.parse(localStorage.getItem(name));
    },
    handlerError: (error) => {
      const result = error.response.data;
      const self = this;
      if (typeof (error.response.data) === 'object') {
        Object.entries(result).forEach(([key, value]) => { window.$swal.fire('Error!', `${key}: ${value}`, 'error'); });
        return false;
      }
      self.$swal.fire('Error!', result, 'error');
      return false;
    },
    getValidationClass (fieldName) {
      const field = this.$v.instance[fieldName]
      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    clearForm () {
      this.$v.$reset()
    },
    validForm () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.save()
      }
    },
    save() {
      const self = this
      if (!this.instance.account) {
        self.$swal.fire('Error!', 'La cuenta contable es requerida.', 'error')
        return false
      }
      if (!this.instance.description) {
        self.$swal.fire('Error!', 'Tiene que elegir una descripción.', 'error')
        return false
      }
      const payload = this.instance
      this.sending = true

      if (this.instance.id) {
        this.putRequest(this.url, payload).then( (data) => {
          self.confSaved = true
          self.sending = false
          self.instance = data.data
        }).catch((error) => {
          self.sending = false
          if (error.response){
            if (typeof (error.response.data) === 'object') {
              const result = error.response.data
              Object.entries(result).forEach(
                ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
              )
              return false
            }
            self.$swal.fire('Error!', error.response.data, 'error')
            return false
          }
          self.$swal.fire('Error!', error, 'error')
        })
        return false;
      }
      payload.company = this.company.id

      this.postRequest(this.url, payload).then((data) => {
        self.confSaved = true
        self.sending = false
        self.instance = data.data
      }).catch((error) => {
        self.sending = false
        if (typeof (error.response.data) === 'object') {
          const result = error.response.data
          Object.entries(result).forEach(
            ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
          )
          return false
        }
        self.$swal.fire('Error!', error.response.data, 'error')
      })
    },
    async sendRequest(payload, url) {
      const result = await this.$http.get(url,{params:  payload});
      return result;
    },
    async postRequest (url, payload) {
      const result = await this.$http.post(url, payload)
      return result
    },
    async putRequest (url, payload) {
      const result = await this.$http.put(`${url}${payload.id}/`, payload)
      return result
    },
    async retrieveRequest (url, _payload) {
      const result = await this.$http.get(url + _payload.id + '/', { params: _payload })
      return result
    },

  },
  filters: {
    status_to_text(value){
      if (value) return 'Sí';
      return 'No';
    },
  },
  computed: {
    settings_obj() {
      return this.$store.state.settings_obj;
    },
  },
};

</script>
<style lang="css" scoped>
 .success {
    font-weight: 500;
    background-color: #4aad09;
  }
  .warning {
    font-weight: 500;
    background-color: yellow;
  }
  .danger {
    font-weight: 500;
    background-color: red;
  }
  table {
    font-size: 2em;
  }
  .customWidthClass{
    width: 10%;
  }
  .moveRigth{
    display: block;
    float: right;
    margin-right: 20%;
    margin-top: 2%;
  }
  .moveLeft{
    display: block;
    float: left;
  }
  .size{
    width: 150px;
    margin-left: 20px;
  }
  .margen{
    margin-left: 450px;
    margin-bottom: 30px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px #424242 inset;
  }
</style>
